import { useGetMenus } from '@/hooks/apis';
import { useRouter } from 'next/router';
import { GetStaticPaths, GetStaticProps } from 'next';
import { dehydrate, QueryClient } from 'react-query';
import { TYPE_QUERY_KEY } from '@/constants/typeQueryKey';
import { aboutUsApi, common, staticPageApi } from '@/services/apis';
import { useMemo } from 'react';
import { listPagesMapping } from '@/constants/configPages';
import { howToGetRateApi } from '@/services/apis/howToGetRate';
import { careerApi } from '@/services/apis/career';
import { useGetAllSlugStaticPage } from '@/hooks/apis/StaticPages/getAllSlugStaticPage';
import { StaticPage } from '@/components';
import { homepageApi } from '@/services/apis/homepage';
import { researchApi } from '@/services/apis/research';

const Index = () => {
  const router = useRouter();
  const slug = router.query?.slug as string;
  const { locale } = useRouter();

  const { data: slugsStaticPage } = useGetAllSlugStaticPage({ locale });
  const { data: menus } = useGetMenus({ locale });

  const suitSlug = useMemo(() => {
    const listSlugsFromMenu = menus?.map(e => e?.slug) ?? [];
    const listSlugsFromStaticPage = slugsStaticPage ?? [];

    const listSlugs = [...listSlugsFromMenu, ...listSlugsFromStaticPage];
    return listSlugs?.find(e => e.indexOf(slug) === 0);
  }, [menus, slug, slugsStaticPage]);

  if (typeof window !== 'undefined' && suitSlug && suitSlug !== slug) {
    router.replace(`./${suitSlug}`, undefined, { locale });
  }

  const PageComponent = useMemo(() => {
    const pageName = menus?.find(page => page?.slug === suitSlug)?.page;

    return (
      listPagesMapping?.find(e => e?.slug === pageName)?.component ??
      listPagesMapping[0].component
    );
  }, [menus, suitSlug]);

  if (slugsStaticPage?.includes(slug)) return <StaticPage />;
  return <>{PageComponent}</>;
};

export const getStaticPaths: GetStaticPaths = async context => {
  const [viPaths, enPaths] = await Promise.all([
    staticPageApi.getAllSlug({ locale: 'vi' }),
    staticPageApi.getAllSlug({ locale: 'en' }),
  ]);

  const viPathsParams = viPaths?.map(path => ({ params: { slug: path } }));
  const enPathsParams = enPaths?.map(path => ({ params: { slug: path } }));

  return {
    paths: [...viPathsParams, ...enPathsParams],
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async context => {
  const { locale, params } = context ?? {};
  const slug = params?.slug as string;
  const queryClient = new QueryClient();

  const careerParams = { populate: 'deep,2', locale };
  const aboutParams = { populate: 'deep,5', locale };

  await Promise.all([
    queryClient.prefetchQuery(
      [TYPE_QUERY_KEY.GET_ALL_RESEARCH, { locale }],
      () => researchApi.getAll({ locale }),
    ),
    queryClient.prefetchQuery(
      [TYPE_QUERY_KEY.GET_RESEARCH_PAGE, { locale }],
      () => researchApi.getResearchPage({ locale }),
    ),
    queryClient.prefetchQuery(
      [TYPE_QUERY_KEY.GET_STATIC_PAGE_BY_SLUG, slug, locale],
      () => staticPageApi.getBySlug({ locale, slug }),
    ),
    queryClient.prefetchQuery(
      [TYPE_QUERY_KEY.GET_ALL_SLUGS_STATIC_PAGE, { locale }],
      () => staticPageApi.getAllSlug({ locale }),
    ),
    queryClient.prefetchQuery([TYPE_QUERY_KEY.GET_MENU, { locale }], () =>
      common.getMenus({ locale }),
    ),
    queryClient.prefetchQuery(
      [TYPE_QUERY_KEY.GET_CAREER_PAGE, careerParams],
      () => careerApi.getCareerPage(careerParams),
    ),
    queryClient.prefetchQuery(
      [TYPE_QUERY_KEY.GET_HOW_TO_GET_RATE, { locale }],
      () => howToGetRateApi.getData({ locale }),
    ),
    queryClient.prefetchQuery([TYPE_QUERY_KEY.GET_MENU], () =>
      homepageApi.getData({ locale }),
    ),
    queryClient.prefetchQuery([TYPE_QUERY_KEY.GET_SETTING], () =>
      common.settingApi({ locale }),
    ),
    queryClient.prefetchQuery(
      [TYPE_QUERY_KEY.GET_ABOUT_US_PAGE, aboutParams],
      () => aboutUsApi.getData(aboutParams),
    ),
  ]);

  return {
    props: {
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
    },
  };
};

export default Index;
