import { AboutUs, Careers, GetRated, Research } from '@/components';

export const listPagesMapping = [
  { slug: 'default', component: <></> },
  {
    slug: 'about-us',
    component: <AboutUs />,
  },
  {
    slug: 'how-to-get-rated',
    component: <GetRated />,
  },
  {
    slug: 'research',
    component: <Research />,
  },
  {
    slug: 'careers',
    component: <Careers />,
  },
];
